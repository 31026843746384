import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BgImage } from 'gbimage-bridge'
import { GatsbyImage } from 'gatsby-plugin-image'

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      {
        heroBackgroundImage: file(relativePath: { eq: "heroBackground.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
        heroLogoImage: file(relativePath: { eq: "heroLogo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 680, quality: 80, layout: CONSTRAINED)
          }
        }
      }
    `
  )

  return (
    <oma-grid-row fullWidth={true}>
      <BgImage
        className='hero'
        image={data.heroBackgroundImage.childImageSharp.gatsbyImageData}
        alt='Stor turbin i gråskala'
      >
        <div className='hero__logo-box'>
          <GatsbyImage
            image={data.heroLogoImage.childImageSharp.gatsbyImageData}
            className='hero__logo-image'
            alt='ECO VS logotyp'
          />
        </div>
      </BgImage>
    </oma-grid-row>
  )
}

export default Hero
