import React from 'react'

import linesDivider from 'images/threeGreenLines01.svg'

import WorkAreaCard from 'components/WorkAreaCard'

const Ventilation = () => {
  return (
    <oma-grid-row class='work-area' id='ventilation'>
      <section className='section section--aligned-with-grid'>
        <h2 className='heading'>Ventilation</h2>
        <img
          src={linesDivider}
          alt='Tre streck i olika nyanser av grönt som delar upp sidans innehåll'
          className='category-divider'
        />
        <div className='work-area-cards'>
          <WorkAreaCard
            heading='Villaventilation'
            headingClass='work-area-card__title work-area-card__title--theme-green'
            subtitle='ECO VS utför ny- & ombyggnationer. Vi gör även:'
          >
            <p className='paragraph paragraph--tiny'>
              Service & reparationer av aggregat. Rengöring av aggregat.
              Rensning av ventilationskanaler. Filterbyten. Injustering av
              luftflöden. Funktionskontroll av ventilationen i din bostad.
              Rådgivning. Aggregatbyten.
            </p>
          </WorkAreaCard>

          <WorkAreaCard
            heading='Ovk'
            headingClass='work-area-card__title work-area-card__title--olive-green'
            subtitle='ECO VS utför OVK-besiktningar i alla typer av fastigheter.'
          >
            <p className='paragraph paragraph--tiny'>
              ECO VS erbjuder även förhandsbesiktning för att du ska veta om det
              är några brister i din anläggning. Om så är fallet kan vi hjälpa
              till med att åtgärda dem.
            </p>
          </WorkAreaCard>

          <WorkAreaCard
            heading='Montering'
            headingClass='work-area-card__title work-area-card__title--theme-green'
            subtitle='ECO VS utför alla typer av installationer för ventilationssystem.'
          >
            <p className='paragraph paragraph--tiny'>
              Några exempel är ombyggnationer, verksamhetsanpassningar och
              installationer av nya anläggningar. Vår främsta uppgift är att
              tillgodose era önskemål på ett energi- och kostnadseffektivt sätt.
            </p>
          </WorkAreaCard>

          <WorkAreaCard
            heading='Injustering'
            headingClass='work-area-card__title work-area-card__title--sea-green'
            subtitle='ECO VS utför alla typer av installationer för ventilationssystem.'
          >
            <p className='paragraph paragraph--tiny'>
              Några exempel är ombyggnationer, verksamhetsanpassningar och
              installationer av nya anläggningar. Vår främsta uppgift är att
              tillgodose era önskemål på ett energi- och kostnadseffektivt sätt.
            </p>
          </WorkAreaCard>
        </div>
      </section>
    </oma-grid-row>
  )
}

export default Ventilation
