import React from 'react'

import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import linesDivider from 'images/threeGreenLines01.svg'

const IntroSection = () => {
  const data = useStaticQuery(
    graphql`
      {
        workOnRoof: file(relativePath: { eq: "workOnRoof.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
      }
    `
  )

  return (
    <oma-grid-row fullWidth class='intro-section'>
      <section className='section section--aligned-with-grid'>
        <h1 className='heading heading--title'>
          Eco vs utför allt inom ventilation & service
        </h1>
        <img
          src={linesDivider}
          alt='Tre streck i olika nyanser av grönt som delar upp sidans innehåll'
        />
        <p className='paragraph'>
          Lång erfarenhet kombinerad med ambitionen att ge våra kunder ett
          personligt bemötande och en personlig service har gett oss kundernas
          förtroende. Vi servar och erbjuder smarta lösningar som bidrar till
          ett bra inomhusklimat, rätt temperatur och minskad energianvändning.
        </p>
        <Link to='#kontakt'>
          <button className='cta'>KONTAKTA OSS</button>
        </Link>
      </section>
      <GatsbyImage
        image={data.workOnRoof.childImageSharp.gatsbyImageData}
        alt='Anställd hos ECO VS jobbar uppe på ett tak'
      />
    </oma-grid-row>
  )
}

export default IntroSection
