import React from 'react'

const WorkAreaCard = ({ heading, headingClass, subtitle, children }) => {
  return (
    <div className='work-area-card'>
      <h4 className={headingClass}>{heading}</h4>
      <p className='subtitle'>{subtitle}</p>
      {children}
    </div>
  )
}

export default WorkAreaCard
