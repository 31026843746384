import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const DividerBlock = ({
  leftImage,
  rightImage,
  altLeftImage,
  altRightImage,
}) => {
  return (
    <oma-grid-row fullWidth class='divider-block'>
      <GatsbyImage image={leftImage} alt={altLeftImage} />
      <GatsbyImage image={rightImage} alt={altRightImage} />
    </oma-grid-row>
  )
}

export default DividerBlock
