import React from 'react'

import linesDivider from 'images/threeGreenLines01.svg'

import WorkAreaCard from 'components/WorkAreaCard'

const Service = () => {
  return (
    <oma-grid-row class='work-area' id='service'>
      <section className='section section--aligned-with-grid'>
        <h2 className='heading'>Service</h2>
        <img
          src={linesDivider}
          alt='Tre streck i olika nyanser av grönt som delar upp sidans innehåll'
          className='category-divider'
        />
        <div className='work-area-cards'>
          <WorkAreaCard
            heading='Service'
            headingClass='work-area-card__title work-area-card__title--sea-green'
            subtitle='ECO VS erbjuder service & underhåll på alla typer av luftbehandlingssystem.'
          >
            <p className='paragraph paragraph--tiny'>
              Vi kan även erbjuda servisavtal där vi kommer regelbundet och gör
              service. Genom servicebesöket säkerställer vi funktion,
              luftkvalitet och inneklimat. Vi rättar till eventuella brister,
              ger förslag till energibesparingar och ombesörjer att rätt
              åtgärder genomförs.
            </p>
          </WorkAreaCard>

          <WorkAreaCard
            heading='Energi'
            headingClass='work-area-card__title work-area-card__title--olive-green'
            subtitle='ECO VS kan erbjuda att kontinuerligt se över hela din fastighet.'
          >
            <p className='paragraph paragraph--tiny'>
              Genom att se över det befintliga systemet, byta ut trasiga delar,
              välja energieffektiva produkter, installera återvinning &
              behovsstyrning ser vi till att våra kunder kan göra lönsamma
              investeringar i bra inomhusklimat, effektiv energianvändning och
              låga driftskostnader.
            </p>
          </WorkAreaCard>

          <WorkAreaCard
            heading='Felsökning'
            headingClass='work-area-card__title work-area-card__title--theme-green'
            subtitle='Du kanske inte kan sätta fingret på vad som är fel, men något känns fel...'
          >
            <p className='paragraph paragraph--tiny'>
              ECO VS hjälper dig att utreda. Vanliga fel vi stöter på är drag,
              lukt, ljud, för varmt/kallt, förhöjd energiförbrukning och
              läckage. Vi hjälper dig att gå till botten med problemet och
              utreder vad som är fel även om det kanske inte alltid har med
              ventilationen att göra.
            </p>
          </WorkAreaCard>
        </div>
      </section>
    </oma-grid-row>
  )
}

export default Service
