import React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

const Employees = ({ fluidImage, name, role, phone, email }) => {
  return (
    <div className='employee'>
      {fluidImage ? (
        <GatsbyImage image={fluidImage} className='employee__image' />
      ) : (
        <div className='employee__image-placeholder' />
      )}
      <div className='employee__information'>
        <h4>{name}</h4>
        {
          role ? (
            <p>{role}</p>
          ) : (
            <p>{'\u00A0'}</p>
          ) /* Using \u00A0 (nbsp;) to force the paragraph to have it's normal height if empty */
        }
        {phone && (
          <p>
            <oma-link to={phone} />
          </p>
        )}
        {email && (
          <p>
            <oma-link to={email} />
          </p>
        )}
      </div>
    </div>
  )
}

export default Employees
