import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import linesDivider from 'images/threeGreenLines01.svg'
import Employees from 'components/Employees'
import SiteMetadata from 'query/siteMetadata'

const Contact = () => {
  const { contactDetails } = SiteMetadata()
  const { niclas, mattias } = contactDetails

  const data = useStaticQuery(
    graphql`
      {
        niclasImage: file(relativePath: { eq: "kontakt-niclas.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 80, layout: CONSTRAINED)
          }
        }
        mattiasImage: file(relativePath: { eq: "kontakt-mattias.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 80, layout: CONSTRAINED)
          }
        }
        micaelImage: file(relativePath: { eq: "kontakt-micael.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 80, layout: CONSTRAINED)
          }
        }
      }
    `
  )

  return (
    <oma-grid-row id='kontakt'>
      <section className='section section--aligned-with-grid'>
        <h2 className='heading'>Kontakta oss</h2>
        <img
          src={linesDivider}
          alt='Tre streck i olika nyanser av grönt som delar upp sidans innehåll'
          className='category-divider'
        />
        <div className='employees'>
          <Employees
            fluidImage={data.niclasImage.childImageSharp.gatsbyImageData}
            name='Niclas Illerman'
            role='Arbetsledare'
            phone={niclas.phone}
            email={niclas.email}
          />
          <Employees
            fluidImage={data.mattiasImage.childImageSharp.gatsbyImageData}
            name='Mattias Axell'
            role='Arbetsledare'
            phone={mattias.phone}
            email={mattias.email}
          />
          <Employees
            fluidImage={data.micaelImage.childImageSharp.gatsbyImageData}
            name='Micael Szlachota'
            role='Montör & Service'
          />
          <Employees name='Alexander' />
        </div>
      </section>
    </oma-grid-row>
  )
}

export default Contact
