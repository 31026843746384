import React from 'react'

import linesDivider from 'images/threeGreenLines01.svg'

const Career = () => {
  return (
    <oma-grid-row id='tjanster'>
      <section className='section section--aligned-with-grid'>
        <h2 className='heading'>Tjänster</h2>
        <img
          src={linesDivider}
          alt='Tre streck i olika nyanser av grönt som delar upp sidans innehåll'
          className='category-divider'
        />
        <p className='paragraph'>
          <strong>
            Vi söker aktivt efter engagerade och självständiga medarbetare som
            vill hjälpa till att utveckla oss på ECO VS:
          </strong>
        </p>
        <p className='paragraph'>
          1 st ventilations montör/service-tekniker
          <br />1 st ovk-besiktnings/service-tekniker
        </p>
        <p className='paragraph'>
          Är du intresserad? Ring oss eller maila till en av oss som är
          arbetsledare.
        </p>
      </section>
    </oma-grid-row>
  )
}

export default Career
