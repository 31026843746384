import React from 'react'

import linesDivider from 'images/threeGreenLines01.svg'

import Reference from 'components/Reference'

const References = () => (
  <oma-grid-row fullWidth class='references' id='referenser'>
    <section className='section section--aligned-with-grid'>
      <h2 className='heading'>Referenser</h2>
      <img
        src={linesDivider}
        alt='Tre streck i olika nyanser av grönt som delar upp sidans innehåll'
        className='category-divider'
      />
      <Reference client='Johan Weimer - Karlströms fastigheter'>
        <p className='paragraph paragraph__reference'>
          Jag har använt ECO VS sedan 2013 och tycker att de levererat mycket
          hög kvalité och visat stort engagemang i arbetet. De har haft en bra
          kommunikation med mig och mina hyresgäster i de projekt vi genomfört.
          Jag uppskattar att alltid kunna ringa och fråga om råd och support i
          ventilationsfrågor. ECO VS tar sig alltid tid att hjälpa till, även om
          det inte alltid slutar med en affär.
        </p>
      </Reference>

      <Reference client='Jan-Erik Falk - Drift/Fastighetschef SML Fastigheter'>
        <p className='paragraph paragraph__reference'>
          Vi har valt att använda oss av ECO VS för att de kommer alltid när de
          har lovat, de återrapporterar det som är gjort och de gör ett bra och
          genomtänkt arbete! ECO VS sköter våra OVK-besiktningar samt gör
          återkommande service på våra ventilationsanläggningar!
        </p>
      </Reference>

      <Reference client='Ronnie Hancic - Drifttekniker TOSITO'>
        <p className='paragraph paragraph__reference'>
          Mycket kompetent personal som lägger stor vikt vid att göra sina
          kunder nöjda! ECO VS ser helheten och inte bara specifika problem,
          utan ger även förslag till annat som kan behöva åtgärdas! Bra utförda
          arbeten, bra bemötande, städar undan efter sig, håller bestämda
          tidsplaner och ger bra återkoppling till kund! ECO VS är alltid
          tillgängliga och ger snabb hjälp vid akuta händelser.
        </p>
      </Reference>
    </section>
  </oma-grid-row>
)

export default References
