import React from 'react'

const Reference = ({ client, children }) => {
  return (
    <div>
      {children}
      <h5 className='client-name'>{client}</h5>
    </div>
  )
}

export default Reference
