import React from 'react'

import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Hero from 'components/Hero'
import DividerBlock from 'components/DividerBlock'
import IntroSection from 'components/IntroSection'
import Ventilation from 'components/Ventilation'
import Service from 'components/Service'
import References from 'components/References'
import Career from 'components/Career'
import Contact from 'components/Contact'

const Index = ({ data }) => (
  <Layout>
    <Hero />
    <IntroSection />
    <Ventilation />

    <DividerBlock
      leftImage={data.staffWithCar.childImageSharp.gatsbyImageData}
      rightImage={data.manWithTurbine.childImageSharp.gatsbyImageData}
    />

    <Service />

    <DividerBlock
      leftImage={data.manLifting.childImageSharp.gatsbyImageData}
      rightImage={data.logoOnCar.childImageSharp.gatsbyImageData}
    />

    <Career />
    <Contact />
    <References />
  </Layout>
)

export const query = graphql`
  {
    staffWithCar: file(relativePath: { eq: "divider-image01.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { cropFocus: EAST }
          layout: FULL_WIDTH
        )
      }
    }
    manWithTurbine: file(relativePath: { eq: "divider-image02.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { cropFocus: WEST }
          layout: FULL_WIDTH
        )
      }
    }
    manLifting: file(relativePath: { eq: "divider-image03.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { cropFocus: CENTER }
          layout: FULL_WIDTH
        )
      }
    }
    logoOnCar: file(relativePath: { eq: "divider-image04.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          transformOptions: { cropFocus: WEST }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default Index
